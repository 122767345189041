import React from 'react';
import styled, { css } from 'styled-components';

const StyledText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  color: ${(props) => props.theme.palette.text};

  a {
    color: ${(props) => props.theme.palette.primary};
  }

  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width};
      width: 100%;
      margin: 0 auto;

      @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
        max-width: 100%;
      }
    `};

  ${(props) =>
    props.variant === 'title' &&
    css`
      font-weight: 700;
      font-size: 3.125rem;
      line-height: 1.3;
      font-family: BrownStd;

      @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
        font-size: 2.125rem;
      }
    `};

  ${(props) =>
    props.variant === 'subtitle' &&
    css`
      font-weight: 700;
      font-size: 1.65rem;
      line-height: 1.3;
      font-family: BrownStd;

      @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
        font-size: 1.35rem;
      }
    `};

  ${(props) =>
    props.variant === 'ingress' &&
    css`
      font-weight: 400;
      font-size: 1.35rem;
      line-height: 1.3;
      font-family: BrownStd;

      @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
        font-size: 1.1rem;
      }
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.palette[props.color]};
    `};

  ${(props) =>
    props.gutterTop &&
    css`
      margin-top: ${props.theme.spacing[props.gutterTop]};
    `};

  ${(props) =>
    props.gutterBottom &&
    css`
      margin-bottom: ${props.theme.spacing[props.gutterBottom]};
    `};
`;

const Text = ({
  element = 'p',
  variant,
  color,
  gutterBottom,
  align,
  gutterTop,
  width,
  raw,
  children,
}) => {
  if (raw) {
    return (
      <StyledText
        as={element}
        variant={variant}
        width={width}
        align={align}
        color={color}
        gutterTop={gutterTop}
        gutterBottom={gutterBottom}
        dangerouslySetInnerHTML={{ __html: raw }}
      />
    );
  }

  return (
    <StyledText
      as={element}
      variant={variant}
      width={width}
      align={align}
      color={color}
      gutterTop={gutterTop}
      gutterBottom={gutterBottom}
    >
      {children}
    </StyledText>
  );
};

export default Text;
