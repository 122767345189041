export default function calculateResults(answers, config) {
  const paths = config.resultPaths;

  let result = '';

  if (answers.some((f) => paths.noRightsForBPA.path.includes(f))) {
    result = config.resultOptions.noRights;
  } else if (
    answers.some((f) => paths.notLawfullyRightsForBPA.path.includes(f))
  ) {
    result = config.resultOptions.notLawfully;
  } else {
    result = config.resultOptions.lawfully;
  }

  return result;
}
