import styled, { css } from 'styled-components';

const Section = styled.div`
  margin: ${(props) => props.theme.spacing.xlarge} 0;

  @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
    margin: 0;
  }

  ${(props) =>
    props.tight &&
    css`
      margin: ${(props) => props.theme.spacing.small} 0;
    `};

  ${(props) =>
    props.hugTop &&
    css`
      margin-top: 0;
    `};

  ${(props) =>
    props.hugBottom &&
    css`
      margin-bottom: 0;
    `};
`;

export default Section;
