export default function getResultText(result, config, answers) {
  let text = {
    title: 'Du har en lovfestet rett til BPA!',
    subtitle:
      'Ønsker du en kostnadsfri og uforpliktende bistand til å søke om BPA?',
    text2: '',
  };

  if (result === config.resultOptions.noRights) {
    if (answers.some((a) => /child/i.test(a))) {
      // Applies for children quiz only
      text = {
        title: 'Barnet ditt har dessverre ikke rett til BPA',
        subtitle:
          '<a href="https://www.helsedirektoratet.no/rundskriv/pasient-og-brukerrettighetsloven-med-kommentarer/rett-til-helse-og-omsorgstjenester-og-transport/rett-til-brukerstyrt-personlig-assistanse" target="_blank" rel="noreferrer noopener">Retten til brukerstyrt personlig assistanse</a> krever at man har et langvarig assistansebehov, som er definert som «behov utover 2 år». Du har svart nei på dette spørsmålet.',
        text2:
          'Hvis du misforsto, og barnet ditt faktisk har et langvarig behov – eller du bare ønsker å snakke med en av våre BPA-rådgivere, fyll gjerne inn dine kontaktopplysninger, så vil du høre fra oss i løpet av kort tid.',
      };
    } else {
      text = {
        title: 'Du har dessverre ikke rett til BPA',
        subtitle:
          '<a href="https://www.helsedirektoratet.no/rundskriv/pasient-og-brukerrettighetsloven-med-kommentarer/rett-til-helse-og-omsorgstjenester-og-transport/rett-til-brukerstyrt-personlig-assistanse" target="_blank" rel="noopener noreferrer">Retten til brukerstyrt personlig assistanse</a> krever at man har et langvarig assistansebehov, som er definert som «behov utover 2 år». Du har svart nei på dette spørsmålet.',
        text2:
          'Hvis du misforsto, og faktisk har et langvarig behov – eller bare ønsker å snakke med en av våre BPA-rådgivere, fyll gjerne inn dine dine kontaktopplysninger, så vil du høre fra oss i løpet av kort tid.',
      };
    }
  } else if (result === config.resultOptions.notLawfully) {
    // Custom text for elders not above 67 years old.
    if (answers.some((a) => a === 'q_2_adult:no')) {
      text = {
        title: 'Du har per i dag ikke en lovfestet rett til BPA!',
        subtitle:
          'Men dette kan heldigvis endre seg i løpet av kort tid. Regjeringen vil styrke BPA-ordningen og foreslår å fjerne den øvre aldersgrensen på 67 år. Saken er nå til behandling på Stortinget.',
        text2:
          'Ønsker du kostnadsfri og uforpliktende hjelp til å skrive en god BPA-søknad?',
      };
    } else if (answers.some((a) => /child/i.test(a))) {
      // Applies for children quiz only
      text = {
        title: 'Barnet ditt har dessverre ikke en lovfestet rett til BPA.',
        subtitle:
          'Men dere har rett til å søke om BPA og få saken deres individuelt vurdert. Vi i Stendi Assistanse vet hva en god søknad til kommunen bør inneholde, og hjelper dere gjerne med utformingen.',
        text2:
          'Ønsker dere kostnadsfri og uforpliktende hjelp til å skrive en god BPA-søknad?',
      };
    } else {
      text = {
        title: 'Du har dessverre ikke en lovfestet rett til BPA.',
        subtitle:
          'Men du har rett til å søke om BPA og få saken din individuelt vurdert. Vi i Stendi Assistanse vet hva en god søknad til kommunen bør inneholde, og hjelper deg gjerne med utformingen.',
        text2:
          'Ønsker du kostnadsfri og uforpliktende hjelp til å skrive en god BPA-søknad?',
      };
    }
  } else {
    if (answers.some((a) => /child/i.test(a))) {
      text = {
        title: 'Barnet ditt har en lovfestet rett til BPA!',
        subtitle:
          'Basert på svarene dine oppfyller dere de lovmessige kravene for å få innvilget BPA. Neste steg på veien er å skrive en god søknad til kommunen, og vi i Stendi Assistanse hjelper dere gjerne med utformingen.',
        text2:
          'Ønsker dere kostnadsfri og uforpliktende hjelp til å skrive en god BPA-søknad?',
      };
    } else {
      text = {
        title: 'Du har en lovfestet rett til BPA',
        subtitle:
          'Basert på svarene dine oppfyller du de lovmessige kravene for å få innvilget BPA. Neste steg på veien er å skrive en god søknad til kommunen, og vi i Stendi Assistanse hjelper deg gjerne med utformingen.',
        text2:
          'Ønsker du kostnadsfri og uforpliktende hjelp til å skrive din BPA-søknad?',
      };
    }
  }

  return text;
}
