export default function resultTranslator(result, config) {
  switch (result) {
    case config.resultOptions.noRights:
      return 'Ingen rett';
    case config.resultOptions.notLawfully:
      return 'Kan ha rett';
    case config.resultOptions.lawfully:
      return 'Lovfestet rett';
    default:
      return '';
  }
}
