import React from 'react';
import styled from 'styled-components';
import stepCalc from '../utils/stepCalc';
import { QuizContext } from '../context/QuizContext';

const StyledProgressWrapper = styled.div`
  box-sizing: border-box;
  height: 7px;
  width: 337px;
  background-color: ${(props) => props.theme.palette.background};
  position: relative;
  border-radius: 100px;

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQueries.small}) {
    width: 270px;
    text-align: center;
  }
`;

const StyledInnerProgresser = styled.div`
  width: ${(props) => props.width}%;
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  background-color: ${(props) => props.theme.palette.secondary};
  border-radius: 100px;
`;

const ResultText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.palette.text};
`;

const Step = styled.p`
  color: ${(props) => props.theme.palette.text};

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQueries.small}) {
    text-align: center;

    span:first-child {
      font-weight: 700;
    }

    span:nth-child(2) {
      display: inline;
    }
  }
`;

const Progresser = ({ visualStep }) => {
  const { data } = React.useContext(QuizContext);

  // TODO:
  // Fix up this progression values
  // It resets when changing to a sub quiz naturally.

  return (
    <div>
      {data.step < data.quiz.length ? (
        <Step>
          <span>
            {visualStep + 1} / {data.quiz.length} {''}
          </span>
          <span>Har jeg rett til BPA?</span>
        </Step>
      ) : (
        <ResultText>Resultat</ResultText>
      )}

      <StyledProgressWrapper>
        <StyledInnerProgresser width={stepCalc(data.step, data.quiz)} />
      </StyledProgressWrapper>
    </div>
  );
};

export default Progresser;
