import React from 'react';
import config from '../utils/config';
import {
  Form,
  Input,
  Label,
  Checkbox,
  Field,
  TextArea,
  Select,
} from '../styleguide/Form';
import Button from '../styleguide/Button';
import receivers from '../utils/receivers';
import Search from './Downshift';
import Text from '../styleguide/Text';
import Status from '../styleguide/Status';
import { QuizContext } from '../context/QuizContext';
import getAssistanceHours from '../utils/getAssistanceHours';

const RegularForm = ({ result }) => {
  const { methods, data } = React.useContext(QuizContext);
  const assistanceHours = getAssistanceHours(data.answers);

  const [state, setState] = React.useState({
    email: '',
    region: '',
    commune: '',
    county: '',
    name: '',
    phone: '',
    message: '',
    consentBasic: undefined,
    consentEmail: undefined,
    accept: undefined,
    assistanceHours: '',
    formError: false,
  });

  function handleInputChange(e) {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  }

  function handleSelect(region, commune, county) {
    setState({
      ...state,
      region: region,
      commune: commune,
      county: county,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const {
      email,
      region,
      commune,
      county,
      phone,
      name,
      message,
      consentBasic,
      consentEmail,
      accept,
    } = state;
    const url = config.profilerURLs.inquiry;

    const data = {
      email,
      name,
      phone,
      [config.characteristics.region]: region,
      [config.characteristics.county]: county,
      [config.characteristics.commune]: commune,
      [config.characteristics.message]: message,
      [config.characteristics.bpaRights]: result,
      [config.characteristics.accept]: accept,
      [config.characteristics.assistanceHours]: assistanceHours,
      'consent:basic': consentBasic,
      'consent:email': consentEmail,
    };

    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      methods.setSentForm();
    } else {
      setState({
        ...state,
        formError: true,
      });
    }
  }

  console.log(state);

  return (
    <Form onSubmit={handleSubmit}>
      <Field>
        <Label htmlFor="name">
          <span>
            Navn <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="text"
          label="Navn"
          placeholder="Skriv inn ditt fulle navn"
          name="name"
          id="name"
          required
          onChange={handleInputChange}
        />
      </Field>

      <Field>
        <Label htmlFor="phone">
          <span>
            Telefonnummer{' '}
            {state.accept ? <span className="required">*</span> : ''}
          </span>
        </Label>
        <Input
          type="text"
          label="Telefonnummer"
          placeholder="Skriv inn ditt telefonnummer"
          name="phone"
          id="phone"
          required={state.accept}
          onChange={handleInputChange}
        />
      </Field>
      <Field>
        <Label htmlFor="email">
          <span>
            E-post <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="email"
          label="E-post"
          placeholder="Skriv inn din e-postadresse"
          name="email"
          id="email"
          required
          onChange={handleInputChange}
        />
      </Field>
      <Field>
        <Label htmlFor="county">
          <span>
            Kommune <span className="required">*</span>
          </span>
        </Label>
        <Search handleSelect={handleSelect} items={receivers} />
      </Field>

      <Field>
        <Label>
          <span>
            Ønsker du å bli oppringt av en BPA-rådgiver?
            <span className="required">*</span>
          </span>
        </Label>
        <Select
          value={
            typeof state.accept === 'undefined'
              ? ''
              : state.accept
              ? 'true'
              : 'false'
          }
          onChange={(e) => {
            setState({
              ...state,
              accept: e.target.value === 'true',
            });
          }}
          withLabelSpace
          required
        >
          <option value="">Vennligst velg…</option>
          <option value="true">Ja</option>
          <option value="false">Nei</option>
        </Select>
      </Field>

      <Field>
        <Label htmlFor="message">
          <span>
            Henvendelse <span className="required">*</span>
          </span>
        </Label>
        <TextArea
          placeholder="Skriv din melding her..."
          name="message"
          id="message"
          required
          onChange={handleInputChange}
        />
      </Field>

      <Field>
        <Checkbox
          required={true}
          onChange={(e) => {
            setState({
              ...state,
              consentBasic: e.target.checked,
            });
          }}
        >
          Jeg godtar at Stendi behandler mine personopplysninger i tråd med{' '}
          <a
            target="_blank"
            href="https://res.cloudinary.com/rubics/image/upload/v1580221629/Personvernerklaering_Stendi_bzxm7t.pdf"
            rel="noopener noreferrer"
          >
            personvernerklæringen
          </a>
          <span className="required">*</span>
        </Checkbox>
      </Field>
      <Field>
        <Checkbox
          onChange={(e) => {
            setState({
              ...state,
              consentEmail: e.target.checked,
            });
          }}
        >
          Jeg ønsker å motta relevante nyhetsbrev fra Stendi Assistanse.
        </Checkbox>
      </Field>
      <Field>
        <Text>
          <Text element="span" color="primary">
            *
          </Text>
          Feltet må fylles ut.
        </Text>
      </Field>
      {state.formError && (
        <Field>
          <Status>
            Noe gikk galt ved innsending av skjema. Vennligst prøv igjen ved å
            oppdatere nettleservinduet, eller vent noen minutter før du prøver
            på nytt.
          </Status>
        </Field>
      )}
      <Field>
        <Button type="submit">Send</Button>
      </Field>
    </Form>
  );
};

export default RegularForm;
