import React from 'react';
import quizData from '../utils/quizData';

const QuizContext = React.createContext({
  data: {
    quiz: quizData,
    step: 0,
    started: false,
    completed: false,
    answers: [],
    depth: 0,
    formSent: false,
  },
  methods: {
    nextStep: () => {},
    start: () => {},
    setSentForm: () => {},
  },
});

const QuizProvider = ({ children }) => {
  const [state, setState] = React.useState({
    quiz: quizData,
    step: 0,
    started: false,
    completed: false,
    answers: [],
    depth: 0,
    formSent: false,
  });

  function nextStep(answer) {
    if (
      state.step === state.quiz.length - 1 &&
      state.quiz.some((d) =>
        d.alternatives.every((f) => f.leadsTo.length === 0)
      )
    ) {
      setState({
        ...state,
        completed: true,
        step: state.step + 1,
        answers: [...state.answers, answer],
      });

      // bail out.
      return;
    }

    let currentQuiz = state.quiz;
    let resetStep = false;
    let currentDepth = state.depth;

    for (let i = 0; i < state.quiz.length; i++) {
      const alternatives = state.quiz[i].alternatives;

      for (let j = 0; j < alternatives.length; j++) {
        const alternative = alternatives[j];

        if (alternative._id === answer._id) {
          if (alternative.leadsTo.length > 0) {
            currentQuiz = alternative.leadsTo;
            resetStep = true;
            currentDepth = currentDepth + 1;
          }
        }
      }
    }

    setState({
      ...state,
      quiz: currentQuiz,
      step: resetStep ? 0 : state.step + 1,
      answers: [...state.answers, answer],
      depth: currentDepth,
    });
  }

  function start() {
    setState({ ...state, started: true });
  }

  function setSentForm() {
    setState({
      ...state,
      formSent: true,
    });
  }

  const value = {
    data: {
      quiz: state.quiz,
      step: state.step,
      started: state.started,
      completed: state.completed,
      answers: state.answers.map((a) => a._id),
      formSent: state.formSent,
      depth: state.depth,
    },
    methods: {
      nextStep,
      start,
      setSentForm,
    },
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export { QuizContext, QuizProvider };
