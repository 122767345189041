import styled, { css } from 'styled-components';

const InfoBox = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background};
  padding: ${(props) => props.theme.spacing.medium};
  text-align: left;
  line-height: 1.7;

  ${(props) =>
    props.gutterBottom &&
    css`
      margin-bottom: ${props.theme.spacing[props.gutterBottom]};
    `};

  ${(props) =>
    props.gutterTop &&
    css`
      margin-top: ${props.theme.spacing[props.gutterTop]};
    `};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${(props) => props.theme.palette.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default InfoBox;
