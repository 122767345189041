export default {
  gtmId: 'GTM-KJV99S8',
  profilerURLs: {
    application:
      'https://api.profiler.marketing/campaigns/5ed8b00d136bda0004654898/collectors/5ed8b12d136bda00046548d4/collect',
    inquiry:
      'https://api.profiler.marketing/campaigns/5ed8b00d136bda0004654898/collectors/5ed8ca4e136bda0004654fa2/collect',
  },
  characteristics: {
    commune: '5ed77d23d981830004407acd',
    region: '5ecba257f699d60004dc6c31',
    county: '5ed77d1bd981830004407aca',
    message: '5ed8c884136bda0004654f34',
    bpaRights: '5ed8b1ad136bda00046548f2',
    accept: '5ed01f15ee3fd90004317b6b',
    assistanceHours: '5f4313a9f3980e0004ad8eec',
  },
  resultOptions: {
    noRights: 'no_rights',
    notLawfully: 'not_lawfully_right',
    lawfully: 'lawfully_right',
  },
  resultPaths: {
    noRightsForBPA: {
      // if includes....
      path: ['q_1_adult:no', 'q_2_children:no'],
    },
    notLawfullyRightsForBPA: {
      // if includes....
      path: [
        'q_3_children:25-31',
        'q_3_adult:25-31',
        'q_2_adult:no',
        'q_3_children:0-24',
        'q_3_adult:0-24',
      ],
    },
  },
};
