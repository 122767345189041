import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from 'styled-components';
import theme from './utils/theme';
import { QuizProvider } from './context/QuizContext';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QuizProvider>
      <App />
    </QuizProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
