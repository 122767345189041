import styled, { css } from 'styled-components';

const Flex = styled.div`
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  align-items: ${(props) => (props.align ? props.align : 'flex-start')};

  ${(props) =>
    props.container &&
    css`
      display: flex;
      margin: -1rem;

      @media all and (max-width: ${props.theme.mediaQueries.medium}) {
        width: 100%;
        flex-direction: column;
        margin: 0;
        align-items: center;

        ${(props) =>
          props.isBoolType &&
          css`
            flex-direction: row !important;
            flex-wrap: wrap;

            > div {
              flex-basis: initial;
              padding: 1rem 0;

              &:not(:last-child) {
                margin-right: 1rem;
              }
            }
          `};
      }
    `};

  ${(props) =>
    props.item &&
    css`
      padding: 1rem;
      flex-basis: ${(props) => props.basis};

      ${props.fullWidth &&
      css`
        width: 100%;
      `};

      @media all and (max-width: ${props.theme.mediaQueries.small}) {
        flex-basis: 100%;
        padding-bottom: 0;
      }
    `};
`;

export default Flex;
