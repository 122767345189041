import React from 'react';
import config from '../utils/config';
import { QuizContext } from '../context/QuizContext';
import Home from './Home';
import Quiz from './Quiz';
import Result from './Result';
import GoogleTagManager from '../utils/GoogleTagManager';
import Header from './Header';

export default function App() {
  const { data } = React.useContext(QuizContext);

  return (
    <>
      <GoogleTagManager gtmId={config.gtmId} />
      <Header />
      {!data.started && !data.completed && <Home />}
      {data.started && !data.completed && <Quiz />}
      {data.started && data.completed && <Result />}
    </>
  );
}
