import React from 'react';
import Center from '../styleguide/Center';
import ContentWrapper from '../styleguide/ContentWrapper';
import { ButtonExternal } from '../styleguide/Button';
import Text from '../styleguide/Text';
import config from '../utils/config';

function FormSent({ isChildRoute, result }) {
  const route = isChildRoute ? 'barn' : 'voksen';

  if (result === config.resultOptions.noRights) {
    window.history.pushState(null, null, `sendt-ingen-rett-${route}`);
  } else if (result === config.resultOptions.notLawfully) {
    window.history.pushState(null, null, `sendt-kan-ha-rett-${route}`);
  } else {
    window.history.pushState(null, null, `sendt-lovfestet-rett-${route}`);
  }

  return (
    <ContentWrapper>
      <Text
        variant="subtitle"
        color="primary"
        gutterBottom="large"
        align="center"
      >
        Takk - din registrering er mottatt
      </Text>

      <Text gutterBottom="large" align="center">
        Vi vil ta kontakt med deg snarest mulig, og senest innen 24 timer.
      </Text>

      <Center>
        <ButtonExternal
          href="https://stendi.no/bpa"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tilbake til Stendi Assistanse
        </ButtonExternal>
      </Center>
    </ContentWrapper>
  );
}

export default FormSent;
