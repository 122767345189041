import React, { Fragment } from 'react';
import config from '../utils/config';
import resultTranslator from '../utils/resultTranslator';
import { QuizContext } from '../context/QuizContext';
import calculateResult from '../utils/calculateResult';
import getResultText from '../utils/getResultText';
import Center from '../styleguide/Center';
import Progresser from './Progresser';
import { VerticalSpacer } from '../styleguide/Spacer';
import FormSent from './FormSent';
import { HorizontalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';
import ApplicationForm from './ApplicationForm';
import RegularForm from './RegularForm';

function Result() {
  const { data } = React.useContext(QuizContext);
  const result = calculateResult(data.answers, config);
  const text = getResultText(result, config, data.answers);
  const isChildRoute = data.answers.some((a) => /child/i.test(a));
  const routeText = isChildRoute ? 'barn' : 'voksen';

  if (result === config.resultOptions.noRights) {
    window.history.pushState(null, null, `ingen-rett-${routeText}`);
  } else if (result === config.resultOptions.notLawfully) {
    window.history.pushState(null, null, `kan-ha-rett-${routeText}`);
  } else {
    window.history.pushState(null, null, `lovfestet-rett-${routeText}`);
  }

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block tighter>
                {data.formSent ? (
                  <FormSent isChildRoute={isChildRoute} result={result} />
                ) : (
                  <Fragment>
                    <Center>
                      <Progresser />
                    </Center>
                    <Text
                      variant="subtitle"
                      width="75%"
                      color="primary"
                      gutterTop="xlarge"
                      gutterBottom="large"
                      align="center"
                    >
                      {text.title}
                    </Text>
                    <Text
                      align="center"
                      gutterBottom="large"
                      width="60%"
                      raw={text.subtitle}
                    />
                    {text.text2 && (
                      <Text align="center" gutterBottom="large" width="60%">
                        {text.text2}
                      </Text>
                    )}

                    <Fragment>
                      {result === config.resultOptions.lawfully ||
                      result === config.resultOptions.notLawfully ? (
                        <ApplicationForm
                          result={resultTranslator(result, config)}
                        />
                      ) : (
                        <RegularForm
                          result={resultTranslator(result, config)}
                        />
                      )}
                    </Fragment>
                  </Fragment>
                )}
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Result;
