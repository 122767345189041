import React from 'react';
import styled from 'styled-components';
import Button from '../styleguide/Button';
import Flex from '../styleguide/Flex';
import { HorizontalSpacer, VerticalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';
import { QuizContext } from '../context/QuizContext';
import InfoBox from '../styleguide/InfoBox';
import Center from '../styleguide/Center';
import Progresser from './Progresser';

const IllustrationWrapper = styled.div`
  position: absolute;
  width: 200px;
  bottom: -4rem;
  left: 4rem;
  img {
    width: 100%;
  }
  @media all and (max-width: ${(props) => props.theme.mediaQueries.medium}) {
    position: static;
  }
`;

const Gfx = styled.img`
  width: 100px;

  @media all and (min-width: ${(props) => props.theme.mediaQueries.small}) {
    width: 200px;
  }
`;

function Quiz() {
  const { data, methods } = React.useContext(QuizContext);
  const current = data.quiz[data.step];
  const illustration = data.quiz[data.step].featuredGxf;
  const step = data.step;
  const depth = data.depth;

  window.history.pushState(null, null, `${depth}_${step}`);

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block centered tighter>
                {data.depth === 1 && (
                  <Center>
                    <Progresser visualStep={step} />
                  </Center>
                )}

                <Text
                  variant="subtitle"
                  color="primary"
                  align="center"
                  gutterTop={data.depth === 1 ? 'xlarge' : undefined}
                  gutterBottom="large"
                >
                  {current.question}
                </Text>

                {current.info && (
                  <InfoBox gutterTop="medium" gutterBottom="xlarge">
                    <div dangerouslySetInnerHTML={{ __html: current.info }} />
                  </InfoBox>
                )}

                <Flex
                  container
                  justify="center"
                  isBoolType={current.alternatives.some(
                    (a) => a.type !== 'text' || a.leadsTo.length > 0
                  )}
                >
                  {current.alternatives.map((alternative) => {
                    let value = null;
                    switch (alternative.type) {
                      case 'text':
                        value = alternative.value;
                        break;
                      case 'boolean':
                        value = alternative.value ? 'Ja' : 'Nei';
                        break;
                      default:
                        value = alternative.value ? 'Ja' : 'Nei';
                        break;
                    }

                    return (
                      <Flex item key={alternative._id}>
                        {alternative.gfx && (
                          <Gfx src={alternative.gfx} alt={'Grafisk element'} />
                        )}
                        <Button
                          fullWidth={
                            alternative.css.fullWidth
                              ? alternative.css.fullWidth
                              : false
                          }
                          gutterTop={
                            alternative.css.gutterTop
                              ? alternative.css.gutterTop
                              : undefined
                          }
                          onClick={() => methods.nextStep(alternative)}
                        >
                          {value}
                        </Button>
                        {alternative.info && (
                          <Text gutterBottom="small" gutterTop="small">
                            {alternative.info}
                          </Text>
                        )}
                      </Flex>
                    );
                  })}
                </Flex>
              </Block>
              {!!illustration && (
                <IllustrationWrapper>
                  <img src={illustration} alt="Illustrasjon" />
                </IllustrationWrapper>
              )}
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Quiz;
