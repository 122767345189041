function isEqualTo(answers, answer) {
  return answers.some((f) => f === answer);
}

export default function getAssistanceHours(answers) {
  let result = '';

  const text = ['0-24 timer', '25-31 timer', '32-99 timer', '100+ timer'];

  if (isEqualTo(answers, 'q_3_children:0-24')) {
    result = text[0];
  } else if (isEqualTo(answers, 'q_3_children:25-31')) {
    result = text[1];
  } else if (isEqualTo(answers, 'q_3_children:32-99')) {
    result = text[2];
  } else if (isEqualTo(answers, 'q_3_children:100-more')) {
    result = text[3];
  } else if (isEqualTo(answers, 'q_3_adult:0-24')) {
    result = text[0];
  } else if (isEqualTo(answers, 'q_3_adult:25-31')) {
    result = text[1];
  } else if (isEqualTo(answers, 'q_3_adult:32-99')) {
    result = text[2];
  } else if (isEqualTo(answers, 'q_3_adult:100-more')) {
    result = text[3];
  }

  return result;
}
