// Personas illustrations
import children_0_24 from './images/20_Stendi_illustrasjoner_BPA_barn_0_24_timer.png';
import children_25_31 from './images/20_Stendi_illustrasjoner_BPA_barn_25_31_timer.png';
import children_32_99 from './images/20_Stendi_illustrasjoner_BPA_barn_32_99_timer.png';
import children_100 from './images/20_Stendi_illustrasjoner_BPA_barn_100_timer.png';
import adult_0_24 from './images/20_Stendi_illustrasjoner_BPA_voksen_0_24_timer.png';
import adult_25_31 from './images/20_Stendi_illustrasjoner_BPA_voksen_25_31_timer.png';
import adult_32_99 from './images/20_Stendi_illustrasjoner_BPA_voksen_32_99_timer.png';
import adult_100 from './images/20_Stendi_illustrasjoner_BPA_voksen_100_timer.png';

// featured graphics for  questions
import infoGraphic1 from './images/20_Stendi_illustrasjoner_BPA9.png';
import infoGraphic2 from './images/20_Stendi_illustrasjoner_BPA11.png';
import infoGraphic3 from './images/20_Stendi_illustrasjoner_BPA12.png';

const subQuizForKids = [
  {
    _id: 'q_1_children',
    question: 'Er barnet under 18 år?',
    info: '',
    featuredGxf: infoGraphic2,
    alternatives: [
      {
        _id: 'q_1_children:yes',
        type: 'boolean',
        info: '',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_1_children:no',
        type: 'boolean',
        info: '',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
  {
    _id: 'q_2_children',
    question: 'Har barnet et langvarig assistansebehov?',
    info:
      '<p>For å ha <a href="https://www.helsedirektoratet.no/rundskriv/pasient-og-brukerrettighetsloven-med-kommentarer/rett-til-helse-og-omsorgstjenester-og-transport/rett-til-brukerstyrt-personlig-assistanse" target="_blank" rel="noopener noreferrer">rett til brukerstyrt personlig assistanse</a> må barnet ha et langvarig assistansebehov, som er definert som «behov ut over 2 år»</p><p style="text-align: right;"><small>Kilde: Helsedirektoratet</small></p>',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_2_children:yes',
        type: 'boolean',
        info: '',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_2_children:no',
        type: 'boolean',
        info: '',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
  {
    _id: 'q_3_children',
    question: 'Hvor mange timer assistanse trenger dere per uke?',
    info:
      'Når man ikke har et vedtak, kan det være vanskelig å vite nøyaktig hvor mange timer man trenger. Velg det som du i dag ser at ditt barn har behov for.',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_3_children:0-24',
        type: 'text',
        info: '',
        /*
        info:
          'Sebastian (14) er en aktiv tenåring med 22 timer BPA i uken. Han har to assistenter som 3-4 dager i uken tar ham med på favorittaktivitetene el-bandy, svømming og sit-ski. Iblant bistår også assistentene ham med å lage familiemiddag eller å rydde rommet. Det Sebastian setter størst pris på med de unge assistentene, er at de gir ham frihet til å leve et mer normalt tenåringsliv – nå trenger han ikke å ha med seg mor eller far overalt.',
        */
        value: '0-24 timer',
        gfx: children_0_24,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_children:25-31',
        type: 'text',
        info: '',
        /*
        info:
          'Amina (8) sitter i elektrisk rullestol og har 28 timer BPA i uken. Assistentene er tilstede hver dag før og etter skolen, og bistår med leksehjelp, toalettbesøk og matlaging. Dette er en god avlastning for foreldrene, som også har tre funksjonsfriske barn de må følge opp. Amina er glad i både shopping og kinobesøk, derfor velger moren hennes å prioritere assistanse noen helger, for å krydre den faste hverdagsordningen med gøye ting.',
        */
        value: '25-31 timer',
        gfx: children_25_31,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_children:32-99',
        type: 'text',
        info: '',
        /*
        info:
          'Martin (3) er utviklingshemmet og har 77 timer BPA i uken. Han er enebarn og kan ikke gå i barnehage grunnet nedsatt immunforsvar. Moren hans er alenemamma, og ønsker å være i jobb. Assistentene er tilstede på både dag- og kveldstid, for å ivareta alle Martins behov. Ved siden av personlig stell og sondenæring bistår de med mye aktivisering og lek, noe moren hans kan se har ført til positiv utvikling for sønnen.',
        */
        value: '32-99 timer',
        gfx: children_32_99,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_children:100-more',
        type: 'text',
        info: '',
        /*
        info:
          'Leah (17) lever med alvorlig funksjonsnedsettelse og har BPA døgnet rundt. De ti assistentene hennes bistår – i tillegg til praktiske oppgaver, med personlig stell og bespisning. Hun har behov for våken nattevakt grunnet pustemaskin. Leah er glad for at assistansen gjør det mulig for henne å følge et normalt undervisningsløp med vennene sine, og hun har planer om å bli sin egen arbeidsleder så snart hun blir myndig.',
        */
        value: '100 timer eller mer',
        gfx: children_100,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
    ],
  },
  {
    _id: 'q_4_children',
    question: 'Kan du selv, eller noen i nær relasjon, være arbeidsleder?',
    info:
      'En arbeidsleder gjør følgende oppgaver: Setter opp vaktplan, har ansvar for at HMS blir fulgt, godkjenner timelister og sender inn informasjonen til Stendi Assistanse. Stendi har arbeidsgiveransvar, og utbetaler, lønn, sykelønn, pensjon og så videre.',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_4_children:yes',
        type: 'boolean',
        info: '',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_4_children:no',
        type: 'boolean',
        info: '',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_4_children:uncertain',
        type: 'text',
        info: '',
        value: 'Usikker',
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
];
const subQuizForAdults = [
  {
    _id: 'q_1_adult',
    question: 'Har du et langvarig assistansebehov?',
    info:
      '<p>For å ha <a href="https://www.helsedirektoratet.no/rundskriv/pasient-og-brukerrettighetsloven-med-kommentarer/rett-til-helse-og-omsorgstjenester-og-transport/rett-til-brukerstyrt-personlig-assistanse" target="_blank" rel="noopener noreferrer">rett til brukerstyrt personlig assistanse</a> må du ha et langvarig assistansebehov, som er definert som «behov ut over 2 år»</p><p style="text-align: right;"><small>Kilde: Helsedirektoratet</small></p>',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_1_adult:yes',
        type: 'boolean',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_1_adult:no',
        type: 'boolean',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
  {
    _id: 'q_2_adult',
    question: 'Er du under 67 år?',
    info: '',
    featuredGxf: infoGraphic3,
    alternatives: [
      {
        _id: 'q_2_adult:yes',
        type: 'boolean',
        info: '',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_2_adult:no',
        type: 'boolean',
        info: '',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
  {
    _id: 'q_3_adult',
    question: 'Hvor mange timer assistanse trenger du per uke?',
    info:
      'Når man ikke har et vedtak, kan det være vanskelig å vite nøyaktig hvor mange timer man trenger. Velg det som du i dag ser at du har behov for.',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_3_adult:0-24',
        type: 'text',
        info: '',
        /*
        info:
          'Cecilie (40) er blind og har 16 timer BPA i uken. Hun har to barn på 3 og 5 år, og hennes to assistenter bistår 2,5 timer om dagen med praktiske oppgaver som morgenstell av barna, matlaging og henting/levering i barnehagen. Hun får også noe bistand til kles- og husvask. Hver uke sparer Cecilie opp 3,5 av uketimene sine, slik at hun kan ha med seg assistentene på spesielle aktiviteter som å reise på ferie.',
        */
        value: '0-24 timer',
        gfx: adult_0_24,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_adult:25-31',
        type: 'text',
        info: '',
        /*
        info:
          'Ali (22) ble skadet i en trafikkulykke og bruker manuell rullestol. Han har 30 timer BPA i uken, fordelt på tre assistenter. Assistansebehovet hans varierer fra uke til uke, han bor alene og klarer seg for det meste selv. Ali bruker i all hovedsak assistentene til å komme seg ut og være sosial, men også som følge til opptrening og for å kunne dra på reise. Assistentene bistår også med noe praktisk, som matlaging og husarbeid.',
        */
        value: '25-31 timer',
        gfx: adult_25_31,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_adult:32-99',
        type: 'text',
        info: '',
        /*
        info:
          'Silvia (58) sitter i elektrisk rullestol og har 70 timer BPA i uken. Hun trenger sine fem assistenter for å få dekket både personlige, praktiske og sosiale behov. Silvia har assistenter hos seg fra morgen til kveld, og de bistår med alt fra av- og påkledning til matlaging, husarbeid, og kjøring for å ordne ulike ærender. Hun er svært glad i klassiske musikkopplevelser, og har med seg assistentene på konsert og så ofte hun kan.',  
        */
        value: '32-99 timer',
        gfx: adult_32_99,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
      {
        _id: 'q_3_adult:100-more',
        type: 'text',
        info: '',
        /*
        info:
          'Håkon (32) har en alvorlig muskelsvinnsykdom, og har døgnkontinuerlig BPA. Med ti assistenter som fordeler døgnet og ukene mellom seg, er han i stand til å bo i egen kjellerleilighet hjemme hos foreldrene sine. Assistentene bistår – i tillegg til praktiske oppgaver, med personlig stell, bespisning, tøyeøvelser og pustetekniske hjelpemidler. Fordi Håkon er en sosial type, er assistentene ofte med ham ut i lystige lag.',
        */
        value: '100 timer eller mer',
        gfx: adult_100,
        leadsTo: [],
        css: {
          fullWidth: true,
          gutterTop: 'small',
        },
      },
    ],
  },
  {
    _id: 'q_4_adult',
    question: 'Kan du selv, eller noen i nær relasjon, være arbeidsleder?',
    info:
      'En arbeidsleder gjør følgende oppgaver: Setter opp vaktplan, har ansvar for at HMS blir fulgt, godkjenner timelister og sender inn informasjonen til Stendi Assistanse. Stendi har arbeidsgiveransvar, og utbetaler, lønn, sykelønn, pensjon og så videre.',
    featuredGxf: null,
    alternatives: [
      {
        _id: 'q_4_adult:yes',
        type: 'boolean',
        info: '',
        value: true,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_4_adult:no',
        type: 'boolean',
        info: '',
        value: false,
        gfx: null,
        leadsTo: [],
        css: {},
      },
      {
        _id: 'q_4_adult:uncertain',
        type: 'text',
        info: '',
        value: 'Usikker',
        gfx: null,
        leadsTo: [],
        css: {},
      },
    ],
  },
];

const quiz = [
  {
    _id: 'q_1',
    question: 'Hvem gjelder BPA-ordningen for?',
    info: '',
    featuredGxf: infoGraphic1,
    alternatives: [
      {
        _id: 'q_1:children',
        type: 'text',
        value: 'Barn',
        gfx: null,
        leadsTo: subQuizForKids,
        css: {},
      },
      {
        _id: 'q_1:adults',
        type: 'text',
        value: 'Voksne',
        gfx: null,
        leadsTo: subQuizForAdults,
        css: {},
      },
    ],
  },
];

export default quiz;
