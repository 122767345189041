import React from 'react';
import { QuizContext } from '../context/QuizContext';
import Button from '../styleguide/Button';
import { HorizontalSpacer, VerticalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';

const Home = () => {
  const { methods } = React.useContext(QuizContext);

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block centered>
                <Text
                  variant="title"
                  color="primary"
                  align="center"
                  gutterBottom="large"
                >
                  Har du rett til BPA?
                </Text>
                <Text variant="subtitle" align="center" gutterBottom="xlarge">
                  Ta testen vår, og finn ut om du har en lovfestet rett til
                  brukerstyrt personlig assistanse.
                </Text>
                <Button outlined onClick={methods.start}>
                  Start test
                </Button>
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
};

export default Home;
